@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts.css";

:root {
    --plyr-color-main: #e2000f;
}

@layer base {
    html {
        @apply text-[2.531645569620253vw];
        @apply sm:text-[0.8333vw];
    }
    b {
        @apply font-semibold;
    }

    p {
        @apply text-base font-semibold;
    }

    h2 {
        @apply text-5xl sm:text-6xl font-semibold uppercase;
    }

    h3 {
        @apply text-2xl sm:text-3xl;
    }

    .red-dot {
        position: relative;
        display: block;
        @apply w-[calc(23/632*100vw)] sm:w-[calc(23/1920*100vw)];
        @apply h-[calc(23/632*100vw)] sm:h-[calc(23/1920*100vw)];
        @apply bg-moskva-red;
        @apply rounded-full;
    }

    .menu {
        position: fixed;
        z-index: 100;
        @apply w-screen transition duration-1000;
        &.menu-desktop {
            @apply hidden sm:block aspect-[1920/132] bg-no-repeat bg-contain bg-[url('images/top-curve.svg')];

            ul {
                position: absolute;
                left: calc(292 / 1920 * 100vw);
                width: calc(1208 / 1920 * 100vw);
                align-items: center;
                li + li {
                    @apply text-base mx-4;
                }
                li a {
                    text-decoration: underline;
                }
            }
        }
        &.menu-mobile {
            @apply sm:hidden aspect-[632/110] bg-cover bg-no-repeat bg-bottom bg-[url('images/top-curve-mobile.svg')];
            ul {
                text-align: center;
                @apply absolute w-screen text-[1.8rem] leading-[2.2rem] top-[18vw];
                li {
                    @apply mb-2;
                    a {
                        text-decoration: underline;
                    }
                }
            }
            .hamburger-bar {
                @apply transition duration-300 absolute w-[calc(74.0/632*100vw)] h-[calc(8/632*100vw)] rounded-full bg-moskva-red;
            }
        }
    }

    .emphasized {
        font-weight: 600;
    }

    .contact-button {
        @apply z-[100] left-[calc(400/632*100vw)] sm:left-[calc(1400/1920*100vw)] top-[calc(592/632*100vw)] sm:top-[calc(755/1920*100vw)] bg-[#0222a9] rounded-full text-white text-base font-semibold px-6 py-2 absolute;
    }

    .contact-button-fixed {
        @apply fixed top-auto bottom-[calc(40/632*100vw)] sm:bottom-[calc(70/1920*100vw)];
    }

    .plyr.plyr--paused .plyr__controls {
        display: none;
    }

    .swiper-button {
        @apply sm:-translate-y-1/2 w-[calc(60/632*100vw)] h-[calc(60/632*100vw)] sm:w-[calc(60/1920*100vw)] sm:h-[calc(60/1920*100vw)] bg-contain bg-no-repeat absolute cursor-pointer bg-[url('images/nav-arrow.png')];
        &.swiper-button-disabled {
            opacity: 0.2;
            cursor: default;
        }
    }

    #section5,
    #section9 {
        & .swiper-button.swiper-button-disabled {
            opacity: 0;
        }
    }

    #section14 {
        .swiper-slide {
            @apply sm:w-[calc(340/1920*100vw)] w-[calc(240/632*100vw)];
            @apply mx-[calc(10/632*100vw)] sm:mx-[calc(20/1920*100vw)];
            height: auto !important;
        }
        .newsfeed {
            @apply w-[calc(520/632*100vw)] sm:w-[calc(1520/1920*100vw)];
        }
    }

    .map {
        @apply absolute 
        bg-contain 
        bg-no-repeat 
        aspect-[1003/722]
        bg-[url('images/map.png')] 
        w-[110vw]
        sm:w-[calc(1003/1920*100vw)] 
        left-[calc(26/632*100vw)] 
        sm:left-[calc(700/1920*100vw)] 
        top-[calc(365/632*100vw)] 
        sm:top-0;
    }

    .loading {
        @apply bg-[url('images/loading.gif')];
    }

    .loading-green {
        @apply bg-[url('images/loading-green.gif')];
    }

}
